<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 268px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <!-- <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1> -->
      </v-col>

      <v-col md="10" class="my-auto py-0">
      
          <v-container fluid>
            <v-row>
              <v-col md="3" class="my-auto py-0">
                <label for="country" class="btx-label mt-2 required">Country</label>
              </v-col>
              <v-col md="9" class="py-0">
                <SelectInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  :items="['Singapore', 'America','Malaysia']"
                  id="country"
                  v-model="address.country"
                  :rules="[vrules.required(address.country, 'Country')]"
                  :class="{ required: !address.country }"
                  placeholder="Country"
                ></SelectInput>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="postal-code" class="btx-label mt-2 required">Postal Code</label>
              </v-col>
              <v-col md="9" class="py-0">
                <PostalCode
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="postal-code"
                  v-model="address.postal_code"
                  :rules="[vrules.required(address.postal_code, 'Postal Code')]"
                  :class="{ required: !address.postal_code }"
                  placeholder="Postal Code"
                ></PostalCode>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="address-line_1" class="btx-label mt-2 required">Address Line 1</label>
              </v-col>
              <v-col md="9" class="py-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="address-line_1"
                  v-model="address.address_line_1"
                  :rules="[vrules.required(address.address_line_1, 'Address Line 1')]"
                  :class="{ required: !address.address_line_1 }"
                  placeholder="Address Line 1"
                ></TextInput>
              </v-col>
              <v-col md="3" class="my-auto py-0">
                <label for="address-line_2" class="btx-label mt-2">Address Line 2</label>
              </v-col>
              <v-col md="9" class="py-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="address-line_2"
                  v-model="address.address_line_2"
                  placeholder="Address Line 2"
                ></TextInput>
              </v-col>

              <v-col md="3" class="my-auto py-0">
                <label for="unit-no" class="btx-label mt-2">Unit No.</label>
              </v-col>
              <v-col md="9" class="py-0">
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="unit-no"
                  v-model="address.unit_number"
                  placeholder="Unit No."
                ></TextInput>
              </v-col>
            </v-row>
          </v-container>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import PostalCode from "@/view/components/PostalCode";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "customer-create",
  title: "Create Customer",
  props: {
    addresss: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      thirdStepValid: false,
      customerId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],
      genderList: [
        {
          text: "Male",
          value: "male",
        },
        {
          text: "Female",
          value: "female",
        },
        {
          text: "Other",
          value: "other",
        },
      ],
      address: {
        country: null,
        postal_code: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    PostalCode,
  },
  watch: {
    address: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param);
        this.$emit("saveAddress", param);
      },
    },
    addresss: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param);
        this.address = param;
      },
    },
  },
  methods: {
    pageTitle() {
      if (this.customerId) {
        return "Update Customer";
      }
      return "Create Customer";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.customerForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_CUSTOMER(_this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        country: customer.country,
        postal_code: customer.postal_code,
        address_line_1: customer.address_line_1,
        address_line_2: customer.address_line_2,
        unit_number: customer.unit_number,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "customer-update") {
      const { id } = params;
      if (id) {
        this.customerId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>
